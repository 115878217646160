import { ErrorFallback } from '@aurelius/components'
import styled from '@emotion/styled'
import { NextPage } from 'next'
import { ErrorBoundary } from 'react-error-boundary'
import { ServerSideProps } from '../serverSideProps'
import { Box } from '@chakra-ui/react'

export const LoginWrapper = styled(Box)`
  border-top: 8px solid var(--colors-layout-highlight);
  display: flex;
  flex: 100%;
  overflow: auto;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export const Unauthenticated: NextPage<ServerSideProps> = ({
  children,
}: React.PropsWithChildren<ServerSideProps>) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }): React.ReactElement => (
        <ErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />
      )}
    >
      <LoginWrapper>{children}</LoginWrapper>
    </ErrorBoundary>
  )
}
