import { CloseWindow, boxProps } from '@aurelius/components'
import styled from '@emotion/styled'
import { BoxProps } from '@chakra-ui/react'
import { pick } from '@styled-system/props'

const IconWrapper = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 13px;
  margin: 1px 18px 6px 0;
  padding: 0 10px 0 10px;
  background-color: var(--colors-hint-icon);
  display: flex;
  align-items: center;
  justify-content: center;
`
const HintTitle = styled.h3`
  font-size: 21px;
  color: var(--colors-hint-icon);
`

const HintWrapper = styled.div<BoxProps>`
  display: flex;
  width: 50vw;
  margin-bottom: 16px;
  background-color: var(--colors-hint-background);
  border-radius: 4px;
  color: #dddddd;
  position: fixed;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
  @media screen and (max-width: 1256px) {
    width: 80vw;
  }
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);

  ${(props) => boxProps(props)}
`

const HintMessage = styled.div`
  margin: 12px;
  padding: 0;
  cursor: pointer;
`
const HintContent = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.19px;
  padding: 18px 0 24px 0;
  color: var(--colors-hint-body);

  & > a {
    color: var(--colors-hint-footer);
    font-weight: bold;
    text-decoration: none;
  }
  & > h5 {
    color: var(--colors-hint-title);
    font-weight: bold;
  }
  & > p {
    color: var(--colors-hint-body);
  }
  & span {
    color: var(--colors-hint-body);
    margin-right: 28px;
  }
`
type HintProps = React.PropsWithChildren<{
  icon?: JSX.Element
  close?: () => void
  title?: string
}>

export const Hint = ({
  children,
  close,
  icon,
  title,
  ...props
}: HintProps & BoxProps) => {
  const hintIcon = icon || <IconWrapper>!</IconWrapper>
  return (
    <HintWrapper {...pick(props)}>
      <HintMessage>{hintIcon}</HintMessage>
      <HintContent>
        {title && <HintTitle>{title}</HintTitle>}
        {children}
      </HintContent>
      {close && (
        <HintMessage onClick={close}>
          <CloseWindow
            fill="var(--colors-hint-close-button)"
            height={32}
            width={32}
          />
        </HintMessage>
      )}
    </HintWrapper>
  )
}

Hint.displayName = 'Hint'
