import Router from 'next/router'
import { ReactElement } from 'react'
import { Login as LoginForm } from '../components/Login'
import { Unauthenticated } from '../layouts/Unauthenticated'
import { ServerSideProps } from '../serverSideProps'
import { NextPageWithLayout } from './_app'

const Page: NextPageWithLayout = (/* pageProps */) => {
  const callback = (): void => {
    Router.push('/projects')
  }
  return <LoginForm onLogin={callback} />
}
Page.displayName = 'LoginPage'

Page.getLayout = (page: ReactElement, pageProps: ServerSideProps) => (
  <Unauthenticated {...pageProps}>{page}</Unauthenticated>
)

export default Page
